import React from 'react'
import '../styles/insert-text.scss'
import { useNewCheckoutContext } from '../context/checkout-context-v2'


const InsertText = ({text}) => {
 const {finCancellation, finComplaints} = useNewCheckoutContext();

  return ( <div >
    <div dangerouslySetInnerHTML={{__html: text === 'cancellation' ? finComplaints  : finCancellation}} 
    className={text === 'complaints' ? 'inserted-text' : 'cancellation-content'}

    // className='inserted-text'
    >
        </div>
  </div>
    
  )
}

export default InsertText