import React from 'react'
import Layout from '../layouts'
import InsertText from '../utils/insert-text'
import { checkTheme } from '../utils/informations'

const CancellationAndClaimProcess = () => {

    
  return (
    <Layout pageTitle={`Cancellation Rights - ${checkTheme().pageTitle}`}>
   

    <InsertText text={'cancellation'}/>
    
    </Layout>
  )
}

export default CancellationAndClaimProcess